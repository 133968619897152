import React, { Component } from "react"
import axios from "axios"

class VerificacionCertificados extends Component {
  constructor() {
    super()

    this.state = {
      clientes: [],
      idCliente: "",
      codigoCertificado: "AR-",
      loteCertificado: "",
      certificadoOk: false,
      certificadoTitle: "",
    }

    this.clientes = []

    this.clienteChangeHandler = this.clienteChangeHandler.bind(this)
    this.codigoChangeHandler = this.codigoChangeHandler.bind(this)
    this.loteChangeHandler = this.loteChangeHandler.bind(this)
    this.requestCertificadoData = this.requestCertificadoData.bind(this)
  }

  requestCertificadoData() {
    const { codigoCertificado, loteCertificado, idCliente } = this.state
    const requestData = {
      RutCli: idCliente,
      accion: "L",
    }

    if (loteCertificado.length === 8) {
      requestData.Lote = loteCertificado
      requestData.Modulo = "L"
    }
    if (codigoCertificado.length === 13) {
      requestData.CodCertificado = codigoCertificado
      requestData.Modulo = "C"
    }

    axios({
      method: "POST",
      url: "https://simet.cl/verificacion/controlCertificados.php",
      data: requestData,
    }).then(response => {
      const { data } = response
      if (data.Existe === "Si") {
        this.setState({
          certificadoOk: true,
          certificadoTitle: data.pdf,
        })
      }
    })
  }

  clienteChangeHandler(e) {
    this.setState({ idCliente: e.target.value })
  }

  codigoChangeHandler(e) {
    const { value } = e.target
    const start = value.slice(0, 3)
    const middle = value.slice(3, 8)
    const end = value.slice(8)
    let codigoCertificado = "AR-"
    if (value.length < 3) {
      codigoCertificado = "AR-"
    }
    if (start !== "AR-") {
      codigoCertificado = "AR-"
    }
    if (middle.length > 0) {
      codigoCertificado += middle
    }
    if (end.length > 0) {
      codigoCertificado += "-" + end.replace(/-/, "")
    }
    this.setState({ codigoCertificado })
  }

  loteChangeHandler(e) {
    this.setState({ loteCertificado: e.target.value })
  }

  componentDidUpdate() {
    const {
      idCliente,
      codigoCertificado,
      loteCertificado,
      certificadoOk,
      certificadoTitle,
    } = this.state

    if (
      (codigoCertificado.length === 13 || loteCertificado.length === 8) &&
      idCliente.length >= 9 &&
      !certificadoOk
    ) {
      this.requestCertificadoData();
    }
    if ((
      (codigoCertificado.length !== 13 && loteCertificado.length !== 8) ||
      idCliente.length < 9
    ) && certificadoOk && certificadoTitle.length > 0
    ) {
      this.setState({
        certificadoOk: false,
        certificadoTitle: ""
      });
    }
  }

  componentDidMount() {
    axios
      .get("https://simet.cl/verificacion/leerclientes.php")
      .then(response => {
        this.setState({
          clientes: response.data.records,
        })
      })
      .catch(e => console.log(e))
  }

  render() {
    const {
      codigoCertificado,
      loteCertificado,
      idCliente,
      certificadoOk,
      certificadoTitle,
      clientes,
    } = this.state
    return (
      <div className="py-3">
        <div className="form-group">
          <label htmlFor="empresa">Empresa</label>
          <select
            name="empresa"
            id="empresa"
            className="form-control"
            value={idCliente}
            onChange={this.clienteChangeHandler}
          >
            <option value="" defaultValue>
              Seleccionar Empresa
            </option>
            {clientes.map(cliente => (
              <option key={cliente.RutCli} value={cliente.RutCli}>
                {cliente.Cliente}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="codigoCertificado">
            Código Certificado
            <span className="small">&nbsp;(Sólo números sin guión)</span>
          </label>
          <input
            type="text"
            name="codigoCertificado"
            id="codigoCertificado"
            className="form-control"
            value={codigoCertificado}
            onChange={this.codigoChangeHandler}
            size="13"
            maxLength="13"
          />
        </div>
        <div className="form-group">
          <label htmlFor="lote">Lote</label>
          <input
            type="text"
            name="lote"
            id="lote"
            className="form-control"
            placeholder="Identificación lote..."
            value={loteCertificado}
            onChange={this.loteChangeHandler}
          />
        </div>
        <div>
          <a
            className={`btn btn-info${certificadoOk ? "" : " disabled"}`}
            href={
              certificadoOk
                ? `https://simet.cl/certificados/certificados/${certificadoTitle}`
                : "#"
            }
            target="_blank"
            role="button"
            aria-disabled={certificadoOk ? "false" : "true"}
          >
            {certificadoOk ? "Descargar Certificado" : "Verificar Certificado"}
          </a>
        </div>
      </div>
    )
  }
}

export default VerificacionCertificados

import React, { Component } from "react"
import axios from "axios"

class VerificacionInformes extends Component {
  constructor() {
    super()
    this.state = {
      numeroInforme: "AM-",
      codigoInforme: "",
      informeOk: false,
      informeTitle: "",
    }

    this.numeroChangeHandler = this.numeroChangeHandler.bind(this)
    this.codigoChangeHandler = this.codigoChangeHandler.bind(this)
    this.requestInformedata = this.requestInformedata.bind(this)
  }

  requestInformedata() {
    const { numeroInforme, codigoInforme } = this.state
    axios({
      method: "POST",
      url: "https://simet.cl/verificacion/controlDatos.php",
      data: {
        CodInforme: numeroInforme,
        CodigoVerificacion: codigoInforme,
        Modulo: "I",
        accion: "L",
      },
    }).then(response => {
      const { data } = response
      if (data.Existe === "Si" && data.CodigoVerificacion === codigoInforme) {
        this.setState({ informeTitle: data.informePDF, informeOk: true })
      }
    })
  }

  numeroChangeHandler(e) {
    const { value } = e.target
    const start = value.slice(0, 3)
    const middle = value.slice(3, 8)
    const end = value.slice(8)
    let numeroInforme = "AM-"
    if (value.length < 3) {
      numeroInforme = "AM-"
    }
    if (start !== "AM-") {
      numeroInforme = "AM-"
    }
    if (middle.length > 0) {
      numeroInforme += middle
    }
    if (end.length > 0) {
      numeroInforme += "-" + end.replace(/-/, "")
    }
    this.setState({ numeroInforme })
  }

  codigoChangeHandler(e) {
    this.setState({ codigoInforme: e.target.value })
  }

  componentDidUpdate() {
    const { codigoInforme, numeroInforme, informeOk, informeTitle } = this.state
    if (numeroInforme.length === 13 && codigoInforme.length === 12) {
      this.requestInformedata()
    } else if (
      (numeroInforme.length !== 13 || codigoInforme.length !== 12) &&
      informeOk &&
      informeTitle.length > 0
    ) {
      this.setState({ informeTitle: "", informeOk: false })
    }
  }

  render() {
    const {
      numeroInforme,
      codigoInforme,
      informeOk,
      informeTitle
    } = this.state
    return (
      <div className="py-3">
        <div className="form-group">
          <label htmlFor="numero">
            Nº de Informe
            <span className="small">&nbsp;(Sólo números sin guión)</span>
          </label>
          <input
            type="text"
            name="numero"
            id="numero"
            size="13"
            maxLength="13"
            value={numeroInforme}
            onChange={this.numeroChangeHandler}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="codigoInforme">Código de Verificación</label>
          <input
            type="text"
            name="codigoInforme"
            id="codigoInforme"
            value={codigoInforme}
            onChange={this.codigoChangeHandler}
            className="form-control"
          />
        </div>
        <div>
          <a
            className={`btn btn-info${informeOk ? "" : " disabled"}`}
            role="button"
            aria-disabled={informeOk ? "false" : "true"}
            target="_blank"
            href={
              informeOk
                ? `https://simet.cl/intranet/informes/${informeTitle}`
                : "#"
            }
          >
            {informeOk ? "Descargar Informe" : "Verificar Informe"}
          </a>
        </div>
      </div>
    )
  }
}

export default VerificacionInformes

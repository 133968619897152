import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import VerificacionInformes from "../components/VerificacionInformes"
import VerificacionCertificados from "../components/VerificacionCertificados"

import infoIcon from "../images/exclamation.png"

class Certificados extends Component {
  render() {
    const { header, body } = this.props.data.allPagesDataJson.edges[0].node
    return (
      <Layout location="/certificados">
        <SEO title="Verificación" />
        <div className="certificados-wrapper">
          <div className="certficados-header">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="title">{header.title}</h2>
                  <p className="lead">{header.text}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="certificados-body">
            <div className="row no-gutters">
              <div className="title-row">
                <h4 className="title">Verificación de Informes</h4>
                <h4 className="title">
                  Verificación de Certificados de Productos
                </h4>
              </div>
              {body.map(e => (
                <div
                  key={e.form}
                  className="col-12 col-md-6 certificados-col pb-5"
                >
                  <h4 className="title d-md-none">{e.title}</h4>
                  <div className="content">
                    <div className="certificados-form">
                      {e.form === "informes" ? (
                        <VerificacionInformes />
                      ) : (
                        <VerificacionCertificados />
                      )}
                    </div>
                    <div className="card">
                      <div className="card-body">
                        {e.important ? (
                          <p className="important">{e.important}</p>
                        ) : (
                          ""
                        )}
                        <img src={infoIcon} alt="Info" className="img-fluid" />
                        {e.info}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allPagesDataJson(filter: { route: { eq: "certificados" } }) {
      edges {
        node {
          header {
            title
            text
          }
          body {
            title
            form
            info
            important
          }
        }
      }
    }
  }
`

export default Certificados
